import React from 'react';
import { CardProps } from './CardProps';
import { CardText, CardTextProps } from './CardText';
import classname from 'classnames';
import { st, classes } from './Card.st.css';

export interface InfoCardProps extends CardProps, CardTextProps {}

export const InfoCard: React.FunctionComponent<InfoCardProps> = (
  props: InfoCardProps,
) => {
  const { divider, title, subtitle, dataHook, className, ...rest } = props;
  return (
    <CardText
      subtitle={subtitle}
      title={title}
      dataHook={dataHook}
      {...rest}
      className={st(classes.root, {}, classname(classes.info, className))}
    />
  );
};
InfoCard.displayName = 'InfoCard';
