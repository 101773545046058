import React from 'react';
import { IPagesContext, PagesContext } from './PagesContext';
import { st, classes } from './page.st.css';

interface PageProps {
  name: string;
  tabId: string;
  children?: React.ReactNode;
  withSideGutters?: boolean;
  mobile?: boolean;
}

export const Page: React.FunctionComponent<PageProps> = ({
  children,
  name,
  tabId,
  withSideGutters,
  mobile,
}: PageProps) => {
  return (
    <PagesContext.Consumer>
      {({ activePage }: IPagesContext) => {
        if (activePage !== name) {
          return null;
        }

        return (
          <section
            aria-labelledby={tabId}
            className={st(classes.root, { mobile, withSideGutters } as any)}
          >
            {children}
          </section>
        );
      }}
    </PagesContext.Consumer>
  );
};
