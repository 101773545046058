import React from 'react';

import { classes } from './Private.st.css';
import { withTranslation, WithTranslation } from '@wix/yoshi-flow-editor';
import { EmptyState } from '../../EmptyState/EmptyState';
import { MembershipButton } from '../../Membership/MembershipButton';

const PrivateComponent: React.FunctionComponent<WithTranslation> = (props) => {
  const { t } = props;

  return (
    <EmptyState
      className={classes.emptyState}
      title={t('groups-web.group.private')}
      content={t('groups-web.join.private')}
      button={<MembershipButton biOrigin="group_feed_inside_page_btn" />}
      dataHook="request-to-join"
    />
  );
};

export const Private = withTranslation()(
  PrivateComponent,
) as React.ComponentType;
