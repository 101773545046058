import React from 'react';
import { Popover } from 'wix-ui-core/popover';
import { IconButton, Skins } from 'wix-ui-tpa/IconButton';
import { st, classes } from './ThreeDots.st.css';
import { EllipsesIcon } from '../icons/EllipsesIcon';
import { ActionsMenuLayout } from 'wix-ui-tpa/ActionsMenuLayout';
import { ActionsMenuLayoutItemProps } from 'wix-ui-tpa/dist/src/components/ActionsMenuLayout/Item/ActionsMenuLayoutItem';
import { withTheme, Theme } from '../../../../common/context/theme';

export type ThreeDotsActions = ActionsMenuLayoutItemProps;
interface ThreeDotsProps {
  isOpen: boolean;
  onClose(): void;
  onOpen(e?: any): void;
  items: ThreeDotsActions[];
  iconClassName?: string;
  className?: string;
}

const closeCodes = ['Escape', 'Tab'];

const ThreeDotsComponent: React.FunctionComponent<ThreeDotsProps & Theme> = (
  props: ThreeDotsProps & Theme,
) => {
  const {
    isOpen,
    onClose,
    items,
    onOpen,
    iconClassName,
    forceBlackAndWhite,
    ...restProps
  } = props;

  React.useEffect(() => {
    if (typeof document === 'undefined') {
      return;
    }

    const listener = ({ code }: KeyboardEvent) => {
      if (closeCodes.includes(code)) {
        onClose();
      }
    };

    document.addEventListener('keydown', listener, true);

    return () => document.removeEventListener('keydown', listener, true);
  }, [isOpen, onClose]);

  return (
    <Popover
      appendTo="parent"
      shown={isOpen}
      placement="bottom-end"
      onClickOutside={onClose}
      className={st(
        classes.root,
        { bw: !!forceBlackAndWhite },
        restProps.className,
      )}
    >
      <Popover.Element>
        <IconButton
          onClick={onOpen}
          skin={Skins.Full}
          className={st(classes.icon, {}, iconClassName)}
          icon={<EllipsesIcon width={24} height={24} />}
          aria-label="Actions menu"
        />
      </Popover.Element>
      <Popover.Content>
        <ActionsMenuLayout className={classes.actionsMenu}>
          {items.map((p) => (
            <ActionsMenuLayout.Item key={p.content} {...p} />
          ))}
        </ActionsMenuLayout>
      </Popover.Content>
    </Popover>
  );
};

export const ThreeDots = withTheme(
  ThreeDotsComponent,
) as React.ComponentType<ThreeDotsProps>;
