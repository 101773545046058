import React from 'react';
import { CardProps } from './CardProps';
import { CardText, CardTextProps } from './CardText';
import { CardWrapper } from './CardWrapper';
import { st, classes } from './Card.st.css';

interface IconCardProps extends CardProps, CardTextProps {
  icon: React.ReactNode;
}

export const IconCard: React.FunctionComponent<IconCardProps> = (
  props: IconCardProps,
) => {
  const { divider, icon, title, subtitle, dataHook, ...rest } = props;
  return (
    <CardWrapper
      divider={divider}
      className={st(classes.root, {}, rest.className)}
    >
      <span className={classes.icon}>{icon}</span>
      <CardText subtitle={subtitle} title={title} dataHook={dataHook} />
    </CardWrapper>
  );
};
