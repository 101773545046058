import React from 'react';
import classnames from 'classnames';
// WIX-UI-TPA
import { AvatarSize } from 'wix-ui-tpa/Avatar';

// API
import { memberWrapper } from '@wix/social-groups-api/dist/src/model/Member/Member';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';

import { DATA_HOOKS } from '../dataHooks';
// STYLES
import { classes } from '../About.st.css';
import * as infoStyles from './GroupInfo.st.css';
import { withTranslation, WithTranslation } from '@wix/yoshi-flow-editor';
import { Box } from '../../../../../common/components/Box/Box';
import { IconCard } from '../../../../../common/components/Cards/IconCard';
import { Clock } from '../../../../../common/components/Icons/Clock';
import { UserCard } from '../../../../../common/components/Cards/UserCard';
import { Header } from '../../../../../common/components/Text/Header';
import { useGroupInfoLabels } from '../useGroupInfoLabels';

interface GroupInfoProps {
  group: ApiTypes.v1.GroupResponse;
}

const GroupInfoComponent = (props: GroupInfoProps & WithTranslation) => {
  const { t, group } = props;
  const {
    createdAt,
    privacy: { title, icon, label },
    visibility,
  } = useGroupInfoLabels(group);
  const { name, imageUrl } = memberWrapper(group.createdBy!);
  return (
    <Box className={classnames(classes.root, infoStyles.classes.root)}>
      <div className={classes.headerWrapper}>
        <Header className={classes.header}>{t('groups-web.group-info')}</Header>
      </div>
      <div className={infoStyles.classes.wrapper}>
        <div className={infoStyles.classes.row}>
          <IconCard
            className={infoStyles.classes.card}
            icon={
              <div className={infoStyles.classes.groupTypeIcon}>{icon}</div>
            }
            title={t(title!)}
            subtitle={t(label!)}
          />
        </div>
        {visibility ? (
          <div className={infoStyles.classes.row}>
            <IconCard
              className={infoStyles.classes.card}
              icon={
                <div className={infoStyles.classes.groupTypeIcon}>
                  {visibility.icon}
                </div>
              }
              title={t(visibility.title)}
              subtitle={t(visibility.label)}
            />
          </div>
        ) : null}
        <div className={infoStyles.classes.row}>
          <IconCard
            className={infoStyles.classes.card}
            icon={<Clock />}
            title={`${t('groups-web.created')} ${createdAt}`}
          />
        </div>
        <div className={infoStyles.classes.createdByRow}>
          <UserCard
            className={infoStyles.classes.card}
            subtitle={`${t('groups-web.createdBy')}:`}
            name={name!.nick || t('groups-web.member.anonymous')}
            pic={imageUrl}
            avatarSize={AvatarSize.small}
            dataHook={DATA_HOOKS.createdBy}
            reverse
          />
          {/* <ChatIcon className={infoStyles.chatIcon} /> */}
        </div>
      </div>
    </Box>
  );
};

export const GroupInfo = withTranslation()(
  GroupInfoComponent,
) as React.ComponentType<GroupInfoProps>;
