import React from 'react';
import { useUser } from '../../../../common/context/user/useUser';
import { UserStatus } from '../../../../common/context/user/IUserContext';
import { EmptyState } from '../EmptyState/EmptyState';
import { classes, st } from './Members.st.css';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Members } from './Members';

interface Props {}

export const MembersPage: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { userActions, userStatus } = useUser();
  if (userStatus === UserStatus.UNKNOWN) {
    return (
      <EmptyState
        className={classes.emptyState}
        title={t('group-web.members.anonymous-state.title')}
        content={t('group-web.members.anonymous-state.description')}
        button={{
          label: t('group-web.members.anonymous-state.button'),
          onClick: () => userActions.promptLogin({ modal: true }),
        }}
      />
    );
  }
  return <Members withMoreActions={true} />;
};

MembersPage.displayName = 'MembersPage';
