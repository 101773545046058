import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { MembershipButton } from '../../Membership/MembershipButton';
import { classes } from '../../Discussion/Private/Private.st.css';
import { EmptyState } from '../../EmptyState/EmptyState';

interface Props {}

export const RestrictedJoin: React.FC<Props> = ({}) => {
  const { t } = useTranslation();

  return (
    <EmptyState
      className={classes.emptyState}
      title={t('groups-web.restriction.join')}
      content={t('groups-web.restriction.join.subtitle')}
      button={<MembershipButton biOrigin="page_btn" />}
      dataHook="restricted-join"
    />
  );
};

RestrictedJoin.displayName = 'RestrictedJoin';
