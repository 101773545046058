import React from 'react';
import { Loader } from './Loader';
import { st, classes } from './Loading.st.css';

interface LoadingProps {
  className?: string;
}

export const Loading: React.FunctionComponent<LoadingProps> = (
  props: LoadingProps,
) => {
  return (
    <div className={st(classes.root, {}, props.className)}>
      <Loader className={st(classes.loader)} />
    </div>
  );
};

Loading.displayName = 'Loading';
