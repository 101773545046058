import React from 'react';
import { ModalProps } from '../../../../common/components/Modal/Modal';
import { withTranslation, WithTranslation } from '@wix/yoshi-flow-editor';
import { Dialog } from '../../../../common/components/Dialog/Dialog';

interface DeleteDialogComponentProps extends ModalProps {
  groupTitle: string;

  onDelete(): void;
}

interface DeleteDialogProps
  extends WithTranslation,
    DeleteDialogComponentProps {}

const DeleteDialogComponent = (props: DeleteDialogProps) => {
  const { t, onDelete, groupTitle, ...rest } = props;
  const title = t('groups-web.group.actions.delete.group');
  const text = t('groups-web.group.actions.delete.confirm', {
    groupName: groupTitle,
  });
  const cancel = t('groups-web.cancel');
  const confirm = t('groups-web.group.actions.delete');
  return (
    <Dialog
      title={title}
      caption={text}
      okLabel={confirm}
      cancelLabel={cancel}
      onOkClick={onDelete}
      {...rest}
    />
  );
};

export const DeleteDialog = withTranslation()(
  DeleteDialogComponent,
) as React.ComponentType<DeleteDialogComponentProps>;
DeleteDialog.displayName = 'DeleteDialog';
