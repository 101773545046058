import React from 'react';
import { Container } from '../Container/Container';
import { Modal, ModalProps } from '../Modal';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import { classes, st } from './ShareModal.st.css';
import { ShareComponent } from './ShareComponent';
import { Theme, withTheme } from '../../context/theme';

interface ShareModalProps extends ModalProps {
  shareUrl: string;
  title: string;
  onShare?(): void;
}

const ShareModalComponent: React.FunctionComponent<ShareModalProps> = (
  props: ShareModalProps & Theme,
) => {
  const {
    isOpen,
    onRequestClose,
    title,
    shareUrl,
    onShare,
    forceBlackAndWhite,
  } = props;

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Container className={st(classes.root, { bw: !!forceBlackAndWhite })}>
        <Text
          typography={TYPOGRAPHY.largeTitle}
          tagName="h2"
          className={classes.title}
        >
          {title}
        </Text>
        <ShareComponent onShare={onShare} shareUrl={shareUrl} />
      </Container>
    </Modal>
  );
};
export const ShareModal = withTheme(
  ShareModalComponent,
) as React.ComponentType<ShareModalProps>;
ShareModal.displayName = 'ShareModal';
