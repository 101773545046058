import React from 'react';
import { CommonErrorProps } from './CommonErrorProps';
import { withTranslation, WithTranslation } from '@wix/yoshi-flow-editor';
import { EmptyState } from '../EmptyState/EmptyState';

type Props = CommonErrorProps & WithTranslation;
export const Error403Component: React.FunctionComponent<Props> = (
  props: Props,
) => {
  const { action, t } = props;
  return (
    <EmptyState
      title={t('groups-web.discussion.error-403.title')}
      content={t('groups-web.discussion.error-403.content')}
      button={{
        label: t('groups-web.discussion.error-403.cta'), // CLOSE SCREEN?
        onClick: () => action(),
      }}
    />
  );
};

Error403Component.displayName = 'Error403';

export const Error403 = withTranslation()(
  Error403Component,
) as React.ComponentType<CommonErrorProps>;
