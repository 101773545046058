import React from 'react';

interface FaceBookIconProps {}
export const FaceBook: React.FunctionComponent<FaceBookIconProps> = (
  props: FaceBookIconProps,
) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <defs>
        <path
          strokeWidth={0}
          id="FaceBook"
          d="M1.325 0C.593 0 0 .593 0 1.325v21.35C0 23.408.593 24 1.325 24h11.494v-9.294H9.692v-3.622h3.127V8.413c0-3.1 1.893-4.788 4.659-4.788 1.324 0 2.463.098 2.795.143v3.24h-1.918c-1.504 0-1.795.715-1.795 1.763v2.313h3.586l-.467 3.622h-3.12V24h6.116c.732 0 1.325-.593 1.325-1.324V1.325C24 .593 23.407 0 22.675 0H1.325z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(0 -.031)">
          <mask id="FaceBook1" fill="#fff">
            <use xlinkHref="#FaceBook" />
          </mask>
          <use fill="#222321" xlinkHref="#FaceBook" />
          <path
            fill="#000"
            d="M0 0h24v24H0z"
            mask="url(#FaceBook1)"
            strokeWidth={0}
          />
        </g>
      </g>
    </svg>
  );
};
