import React from 'react';
import { st, classes } from '../Members.st.css';
import { Avatar, AvatarSize } from 'wix-ui-tpa/Avatar';
import { Text } from 'wix-ui-tpa/Text';
import { DATA_HOOKS } from '../dataHooks';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { GroupMember } from '@wix/social-groups-api/dist/src/model/Member/GroupMember';
import { MemberActions } from './MemberActions';
import { AdminActions } from './AdminActions';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { RoleBadges } from '../../../../../common/components/RoleBadges/RoleBadges';
import { FollowButton } from '../../FollowButton/FollowButton';
import { Theme } from '../../../../../common/context/theme';
import { useUser } from 'common/context/user/useUser';

export interface MemberProps extends Partial<AdminActions>, Theme {
  member: GroupMember;
  mobile: boolean;
  withMoreActions: boolean;
  isCurrentUser: boolean;
  showMemberActions: boolean;
  secondary?: React.ReactElement;
}

export const Member = (props: MemberProps) => {
  const { t } = useTranslation();
  const { userActions } = useUser();
  const {
    member,
    mobile,
    withMoreActions,
    isCurrentUser,
    forceBlackAndWhite,
    showMemberActions,
    secondary,
    ...adminActions
  } = props;

  const memberId = member.getId();
  const imageUrl = member.getImageUrl();
  const name = member.getName(t('groups-web.member.anonymous'));
  const roles = member.getRoles() as ApiTypes.v1.RoleInGroup[];
  const relationship = member.getRelation();
  const isSiteAdmin = member.isSiteAdmin();
  const isGroupAdmin = member.isAdmin();
  const hasAnswers = member.hasAnswers();
  const navigateToUserProfile = () => userActions.openUserProfile(memberId);

  return (
    <li
      key={memberId}
      className={st(classes.memberItem, { bw: !!forceBlackAndWhite })}
      onClick={navigateToUserProfile}
    >
      <Avatar
        size={mobile ? AvatarSize.small : AvatarSize.large}
        src={imageUrl}
        name={name}
      />
      <div className={classes.userInfo}>
        <div className={classes.nickAndRole}>
          <Text data-hook={DATA_HOOKS.username} className={classes.username}>
            {name}
          </Text>
          <RoleBadges
            roles={roles}
            relationship={relationship}
            className={classes.roleBadge}
          />
        </div>
      </div>
      {!mobile && withMoreActions ? (
        <>
          <div className={classes.mainButtons}>
            <FollowButton siteMemberId={memberId} />
          </div>
          <div className={classes.delimiter} />
        </>
      ) : null}
      {showMemberActions && (
        <MemberActions
          memberId={memberId}
          isSiteAdmin={isSiteAdmin}
          isGroupAdmin={isGroupAdmin}
          hasAnswers={hasAnswers}
          {...(adminActions as AdminActions)}
        />
      )}
      {secondary && secondary}
    </li>
  );
};
