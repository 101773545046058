import * as React from 'react';
import { st, classes } from './SortFilterSearch.st.css';
import { ApiTypes, CommonTypes } from '@wix/social-groups-api/dist/src/types';
import { DropdownOptionProps } from 'wix-ui-tpa/dist/src/components/Dropdown/DropdownOption';
import { TPAComponentsContext } from 'wix-ui-tpa/TPAComponentsConfig';
import { withTranslation, WithTranslation } from '@wix/yoshi-flow-editor';
import { SiteMembersContext } from '../../../contexts/SiteMembers/SiteMembers';
import { SearchFilterSortMembers } from '../../../controllers/members/SearchFilterSortMembers';
import { Search } from '../../../../../common/components/Search';
import { Block } from '../../../../../common/components/Block';
import { LabeledDropdown } from '../../../../../common/components/LabeledDropdown/LabeledDropdown';

export interface SortFilterSearchProps {}

type Props = SortFilterSearchProps & WithTranslation;
const SortFilterSearchComponent = (props: Props) => {
  // TODO: mobile
  const { t } = props;
  const { membersActions } = React.useContext(SiteMembersContext);
  const { mobile } = React.useContext(TPAComponentsContext);
  const queryRef = React.useRef<SearchFilterSortMembers>({});

  React.useEffect(() => {
    queryRef.current = {};
    return function cleanup() {
      membersActions.searchFilterSort({});
    };
  }, [membersActions]);

  const searchFilterSort = React.useCallback(() => {
    if (!membersActions || !queryRef.current) {
      return;
    }
    membersActions.searchFilterSort(queryRef.current);
  }, [membersActions]);

  const onFilterChanged = React.useCallback(
    (selectedFilter: DropdownOptionProps) => {
      const role = selectedFilter.id;
      queryRef.current.roles =
        role === 'all' ? undefined : [role as ApiTypes.v1.RoleInGroup];
      searchFilterSort();
    },
    [searchFilterSort],
  );
  const onSortChanged = React.useCallback(
    (selectedSort: DropdownOptionProps) => {
      queryRef.current.sort = {
        field: 'joinedOn',
        order: selectedSort.id as CommonTypes.SortOrder,
      };
      searchFilterSort();
    },
    [searchFilterSort],
  );
  const onSearchChanged = React.useCallback(
    (search) => {
      queryRef.current.name = search;
      searchFilterSort();
    },
    [searchFilterSort],
  );

  const search = <Search withCloseButton={true} onChange={onSearchChanged} />;
  const sortOptions = React.useMemo(
    () => [
      {
        id: CommonTypes.SortOrder.ASC,
        value: t('groups-web.members.sort.newest'),
        isSelectable: true,
      },
      {
        id: CommonTypes.SortOrder.DESC,
        value: t('groups-web.members.sort.oldest'),
        isSelectable: true,
      },
    ],
    [t],
  );
  const filterOptions = React.useMemo(
    () => [
      {
        id: 'all',
        value: t('groups-web.members.filter.all'),
        isSelectable: true,
      },
      {
        id: ApiTypes.v1.RoleInGroup.GROUP_ADMIN,
        value: t('groups-web.members.filter.admins'),
        isSelectable: true,
      },
    ],
    [t],
  );
  return (
    <div className={st(classes.root, { mobile } as any)}>
      <Block
        end={search}
        stylesOverride={{
          gridTemplateColumns: 'minmax(0, min-content) min-content',
        }}
      >
        {!mobile ? (
          <LabeledDropdown
            label={t('groups-web.members.filter')}
            options={filterOptions}
            onChange={onFilterChanged}
            placeholder={t('groups-web.members.filter.all')}
          />
        ) : null}
        <div className={classes.overflowX}>
          <LabeledDropdown
            label={t('groups-web.members.sort')}
            options={sortOptions}
            onChange={onSortChanged}
            placeholder={t('groups-web.members.sort.newest')}
          />
        </div>
      </Block>
    </div>
  );
};

export const SortFilterSearch = withTranslation()(
  SortFilterSearchComponent,
) as React.ComponentType<SortFilterSearchProps>;
SortFilterSearch.displayName = 'SortFilterSearch';
