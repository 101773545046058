import { useGroupV2 } from '../../contexts/Group/useGroupV2';
import { useBi } from '@wix/yoshi-flow-editor';
import { BIUserEntry } from '../../../../common/bi-logger/types';
import {
  groupFeedTopicsAddTopicToPost,
  postActionClick,
} from '@wix/bi-logger-groups/v2';
import {
  groupFeedTopicsAddTopicToPostParams,
  postActionClickParams,
} from '@wix/bi-logger-groups/dist/src/v2/types';

interface BiParams {
  group_id: any;
  groupId: any;
  post_id: string;
  postId: string;
  userEntry: BIUserEntry;
}

type OmitBiParams<T> = Omit<T, keyof BiParams>;

export function useFeedBI(postId: string) {
  const { groupV2 } = useGroupV2();
  const bi = useBi();

  const biParams: BiParams = {
    group_id: groupV2?.id,
    groupId: groupV2?.id,
    post_id: postId,
    postId,
    userEntry: BIUserEntry.SITE,
  };

  return {
    postActionClick(params: OmitBiParams<postActionClickParams>) {
      bi.report(
        postActionClick({
          ...params,
          ...biParams,
        }),
      );
    },
    groupFeedTopicsAddTopicToPost(
      params: OmitBiParams<groupFeedTopicsAddTopicToPostParams>,
    ) {
      bi.report(
        groupFeedTopicsAddTopicToPost({
          origin: 'post_to_the_topic',
          ...params,
          ...biParams,
        }),
      );
    },
  };
}
