import React from 'react';
import { ModalProps } from '../../../../../common/components/Modal/Modal';

import { DATA_HOOKS } from './dataHooks';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';

import { Dialog } from '../../../../../common/components/Dialog/Dialog';
import { leaveGroupClick } from '@wix/bi-logger-groups/v2';

export interface LeaveGroupDialogProps extends ModalProps {
  groupId: string;
  groupTitle: string;
  onLeaveGroup(): void;
}

type Props = LeaveGroupDialogProps;
const LeaveGroupDialogComponent: React.FunctionComponent<Props> = (props) => {
  const { groupId, groupTitle, onLeaveGroup, ...rest } = props;
  const { t } = useTranslation();
  const bi = useBi();
  const leaveTitle = t('groups-web.group.actions.leave.group');
  const leaveText = t('groups-web.group.actions.leave.confirm', {
    groupName: groupTitle,
    interpolation: { escapeValue: false },
  });
  const cancel = t('groups-web.cancel');
  const leave = t('groups-web.group.actions.leave');
  const onLeaveGroupDecorator = () => {
    bi.report(
      leaveGroupClick({
        group_id: groupId,
        origin: 'leave_dialog_modal',
      }),
    );
    onLeaveGroup();
  };

  return (
    <Dialog
      title={leaveTitle}
      caption={leaveText}
      okLabel={leave}
      cancelLabel={cancel}
      onOkClick={onLeaveGroupDecorator}
      data-hook={DATA_HOOKS.leaveGroupDialog}
      {...rest}
    />
  );
};

export const LeaveGroupDialog = LeaveGroupDialogComponent;

LeaveGroupDialog.displayName = 'LeaveGroupDialog';
